import * as React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import SEO from "../components/Seo";

let cmDates = [
  "26.01.2024",
  "23.02.2024",
  "29.03.2024",
  "26.04.2024",
  "31.05.2024",
  "28.06.2024",
  "26.07.2024",
  "30.08.2024",
  "27.09.2024",
  "25.10.2024",
  "29.11.2024",
  "27.12.2024",
  "31.01.2025",
  "28.02.2025",
  "28.03.2025",
  "25.04.2025",
  "30.05.2025",
  "27.06.2025",
  "25.07.2025",
  "29.08.2025",
  "26.09.2025",
  "31.10.2025",
  "28.11.2025",
  "26.12.2025",
];

export default function Home({ data }) {
  let today = new Date();
  let index = 0;
  let nextCMDateString = cmDates[index];
  let pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
  let nextCMDate = new Date(nextCMDateString.replace(pattern, "$3-$2-$1"));
  nextCMDate.setHours(23, 59, 59);

  while (today > nextCMDate) {
    index = index + 1;
    nextCMDateString = cmDates[index];
    nextCMDate = new Date(nextCMDateString.replace(pattern, "$3-$2-$1"));
    nextCMDate.setHours(23, 59, 59);
  }

  // format date
  nextCMDateString = new Intl.DateTimeFormat("de-CH", {
    dateStyle: "full",
  }).format(nextCMDate);

  let randomImageNumber = Math.floor(Math.random() * 14);
  let fluidImage = data.image00.childImageSharp.fluid;
  switch (randomImageNumber) {
    case 0:
      fluidImage = data.image00.childImageSharp.fluid;
      break;
    case 1:
      fluidImage = data.image01.childImageSharp.fluid;
      break;
    case 2:
      fluidImage = data.image02.childImageSharp.fluid;
      break;
    case 3:
      fluidImage = data.image03.childImageSharp.fluid;
      break;
    case 4:
      fluidImage = data.image04.childImageSharp.fluid;
      break;
    case 5:
      fluidImage = data.image05.childImageSharp.fluid;
      break;
    case 6:
      fluidImage = data.image06.childImageSharp.fluid;
      break;
    case 7:
      fluidImage = data.image07.childImageSharp.fluid;
      break;
    case 8:
      fluidImage = data.image08.childImageSharp.fluid;
      break;
    case 9:
      fluidImage = data.image09.childImageSharp.fluid;
      break;
    case 10:
      fluidImage = data.image10.childImageSharp.fluid;
      break;
    case 11:
      fluidImage = data.image11.childImageSharp.fluid;
      break;
    case 12:
      fluidImage = data.image12.childImageSharp.fluid;
      break;
    case 13:
      fluidImage = data.image13.childImageSharp.fluid;
      break;
    default:
      break;
  }

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        height: "100vh",
        // backgroundColor: "red",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <SEO title={"Home"} />
      <div
        style={{
          margin: 20,
          padding: 20,
          // borderWidth: 50,
          backgroundColor: "black",
          width: "50%",
          maxWidth: 500,
        }}
      >
        <Img fluid={fluidImage} imgStyle={{ objectFit: "cover" }} />
      </div>
      <div style={{ textAlign: "center" }}>Nächste Critical Mass in Bern:</div>
      <div style={{ textAlign: "center" }}>{nextCMDateString}</div>
      <div style={{ textAlign: "center" }}>{`Helvetiaplatz: 18:45`}</div>
    </div>
  );
}

export const homePageQuery = graphql`
  query homePageQuery {
    image00: file(relativePath: { eq: "00.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image01: file(relativePath: { eq: "01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image02: file(relativePath: { eq: "02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image03: file(relativePath: { eq: "03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image04: file(relativePath: { eq: "04.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image05: file(relativePath: { eq: "05.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image06: file(relativePath: { eq: "06.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image07: file(relativePath: { eq: "07.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image08: file(relativePath: { eq: "08.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image09: file(relativePath: { eq: "09.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image10: file(relativePath: { eq: "10.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image11: file(relativePath: { eq: "11.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image12: file(relativePath: { eq: "12.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image13: file(relativePath: { eq: "13.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

// const styles = StyleSheet.create({
//   smallTitle: {
//     fontSize: 36,
//     lineHeight: 44,
//     color: colors.allBlack,
//   },
//   title: {
//     fontFamily: "Montserrat",
//     fontStyle: "normal",
//     fontWeight: "bold",
//     fontSize: 48,
//     lineHeight: 59,
//     color: colors.allBlack,
//   },
//   text: {
//     fontFamily: "Montserrat",
//     fontStyle: "normal",
//     fontWeight: "normal",
//     fontSize: 18,
//     lineHeight: 26,
//     color: colors.allBlack,
//   },
//   coverText: {
//     color: colors.allWhite,
//     textShadowColor: colors.halfBlack,
//     textShadowOffset: { width: 2, height: 4 },
//     textShadowRadius: 4,
//   },
//   numbersTitle: {
//     fontSize: 96,
//     lineHeight: 117,
//     color: colors.allBlack,
//   },
// });
